// src/Store/store.js

import { configureStore } from '@reduxjs/toolkit';
import serviceReducer from "./serviceSlice";
import isThankReducer from "./isThankSlice";
import careerDetailReducer from './careerDetailSlice'; // Import the career detail slice
const store = configureStore({
  reducer: {
    service: serviceReducer,
    careerDetail: careerDetailReducer, 
    isThank:isThankReducer,
     // Add the service slice to the Redux store
  },
});

export default store;
