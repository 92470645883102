import { createSlice } from '@reduxjs/toolkit';

// Initial state for the service page
const initialState = {
  isThankYouPage: false,
 
};

const thankYouSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setIsThankYouPage(state, action) {
      state.isThankYouPage = action.payload;
    },
  
  },
});

// Export actions for dispatching
export const { setIsThankYouPage } = thankYouSlice.actions;

// Export the reducer to be added to the store
export default thankYouSlice.reducer;