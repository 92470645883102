
import { createClient } from '@sanity/client';

const sanityClient = createClient({
  projectId: 'hev1c88z',  // Replace with your Sanity project ID
  dataset: 'production',         // Replace with your dataset
  useCdn: false,                 // `false` if you need the freshest data
  apiVersion: '2023-10-16',      // Use the latest API version or a specific date
  token: 'skGJYc1WQGMLbERIH2yqYUGTIxdQ9KcBYop6QpJzIgmz80L0478NzhngLHFtNGmuk02G7F8XhziXiNmEtNIB7l2CSo32Enkvfeje8S7k1zkr8Ss3a9rIwiJpBEPERum6CJCnPodf8S9ZAnI3OtapmqlhxrTsGs8qC3HhL5G5uMDa5T0BrzNX', // If needed for private datasets
});

export default sanityClient;

