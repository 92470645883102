import React from 'react';
import { useState, useEffect, lazy, Suspense, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop";
import SEO from "./Pages/helper/SEO";
import { fetchSEOData } from "./Pages/helper/fetchSEOData";

import "./App.css";
import "./external.css";
import "animate.css/animate.min.css";
import "./custom.css";
import "./responsive.css";
import { WOW } from 'wowjs';

// Lazy load components
const Home = lazy(() => import("./Pages/Home/Home"));
const About = lazy(() => import("./Pages/About/About"));
const Faq = lazy(() => import("./Pages/Faq/Faq"));
const Project = lazy(() => import("./Pages/Project/Project"));
const ProjectDetail = lazy(() => import("./Pages/Project/ProjectDetail"));
const Media = lazy(() => import("./Pages/Media/Media"));
const Services = lazy(() => import("./Pages/Services/Services"));
const ServicesListing = lazy(() => import("./Pages/Services/ServiceListing"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const Career = lazy(() => import("./Pages/Career/Career"));
const CareerDetail = lazy(() => import("./Pages/Career/CareerDetail"));
const BlogListing = lazy(() => import("./Pages/blogListing/BlogListing"));
const BlogDetail = lazy(() => import("./Pages/blogListing/BlogDetail"));
const Thankyou = lazy(() => import("./Pages/Thankyou/Thankyou"));
const NotFound = lazy(() => import("./Pages/NotFound/NotFound"));
const Layout = lazy(() => import("./Layout/Layout"));

function useSEOData(pathname) {
  const [seoData, setSeoData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchSEOData(pathname);
      setSeoData(data);
    };
    fetchData();
  }, [pathname]);

  return seoData;
}

const AppContent = React.memo(() => {
  const location = useLocation();
  const seoData = useSEOData(location.pathname);

  useEffect(() => {
    const wow = new WOW();
    wow.init();
    return () => wow.sync();
  }, []);

  const renderFallback = useCallback(
    () => <div className="loading"></div>,
    []
  );

  return (
    <HelmetProvider>
      <SEO seo={seoData} />
      <Suspense fallback={renderFallback()}>
        <Layout>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/project" element={<Project />} />
            <Route path="/project/:slug" element={<ProjectDetail />} />
            <Route path="/media" element={<Media />} />
            <Route path="/:slug" element={<Services />} />
            <Route path="/service-listing" element={<ServicesListing />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/career" element={<Career />} />
            <Route path="/blogs" element={<BlogListing />} />
            <Route path="/blogs/:category/:slug" element={<BlogDetail />} />
            <Route path="/career/:slug" element={<CareerDetail />} />
            <Route path="/thankyou" element={<Thankyou />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </Suspense>
     
    </HelmetProvider>
  );
});

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
