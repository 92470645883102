import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ seo }) => {
  if (!seo) {
    return null;
  }


  console.log(seo)
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      {/* <title>{title || 'Default Title'}</title>
      <meta name="keywords" content={keywords || 'default, keywords'} />
      <meta name="description" content={description || 'Default description'} />
      <link rel="canonical" href={canonical_tag || window.location.href} /> */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords || 'default, keywords'} />
      <link rel="canonical" href={seo.canonicalTag} />
     
      {/* Open Graph Tags */}
      {seo.ogTitle && <meta property="og:title" content={seo.ogTitle} />}
      {seo.ogDescription && <meta property="og:description" content={seo.ogDescription} />}
      {seo.ogUrl && <meta property="og:url" content={seo.ogUrl} />}
      {seo.ogImage && <meta property="og:image" content={seo.ogImage} />}
      {seo.ogSiteName && <meta property="og:site_name" content={seo.ogSiteName} />}
      {seo.ogLocale && <meta property="og:locale" content={seo.ogLocale} />}
      {seo.ogType && <meta property="og:type" content={seo.ogType} />}

      {/* Twitter Tags */}
      {seo.twitterCard && <meta name="twitter:card" content={seo.twitterCard} />}
      {seo.twitterSite && <meta name="twitter:site" content={seo.twitterSite} />}
      {seo.twitterUrl && <meta name="twitter:site" content={seo.twitterUrl} />}
      {seo.twitterTitle && <meta name="twitter:title" content={seo.twitterTitle} />}
      {seo.twitterDescription && <meta name="twitter:description" content={seo.twitterDescription} />}
      {seo.twitterImageUrl && <meta name="twitter:image" content={seo.twitterImageUrl} />}
      {/* Faq Schema */}
      {seo.faqSchema && (
        <script type="application/ld+json">
          {(seo.faqSchema)}
        </script>
      )}
      {seo.articleSchema && (
        <script type="application/ld+json">
          {(seo.articleSchema)}
        </script>
      )}
      {seo.reviewSchema && (
        <script type="application/ld+json">
          {(seo.reviewSchema)}
        </script>
      )}
      {seo.organisationSchema && (
        <script type="application/ld+json">
          {(seo.organisationSchema)}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;